<template>
  <div id="editprofile" class="tab-pane active tab-box">
    <div class="mt-4">
      <div class="mt-4 position-relative">
        <h6 class="mb-2">{{ $t('First Name') }}</h6>
        <input class="form-control mb-0 h50" type="text" :placeholder="$t('First Name')" aria-label="First Name"
          v-model="$v.profile.user_name.$model" :class="{ 'is-invalid': $v.profile.user_name.$error, }" />
        <div v-if="$v.profile.user_name.$error" class="invalid-feedback">
          <span v-if="!$v.profile.user_name.required">{{ $t('Please enter field', { msg: $t('First Name') }) }}</span>
        </div>
      </div>

      <div class="mt-4 position-relative">
        <h6 class="mb-2">{{ $t('Last Name') }}</h6>
        <input class="form-control mb-0 h50" type="text" :placeholder="$t('Last Name')" aria-label="Last Name"
          v-model="$v.profile.last_name.$model" :class="{ 'is-invalid': $v.profile.last_name.$error, }" />
        <div v-if="$v.profile.last_name.$error" class="invalid-feedback">
          <span v-if="!$v.profile.last_name.required">{{ $t('Please enter field', { msg: $t('Last Name') }) }}</span>
        </div>
      </div>

        <div class="mt-4 position-relative w-75">
          <h6 class="mb-2">{{ $t('Street Name') }}</h6>
          <input class="form-control mb-0 h50" type="text" :placeholder="$t('Street Name')" aria-label="Street Name"
            v-model="$v.profile.street_name.$model" :class="{ 'is-invalid': $v.profile.street_name.$error, }" />
          <div v-if="$v.profile.street_name.$error" class="invalid-feedback">
            <span v-if="!$v.profile.street_name.required">{{ $t('Please enter field', { msg: $t('Street Name') })
            }}</span>
          </div>
        </div>

      <div class="d-flex">
        <div class="mt-4 position-relative w-75 me-5">
          <h6 class="mb-2">{{ $t('City') }}</h6>
          <input class="form-control mb-0 h50" type="text" :placeholder="$t('City')" aria-label="City"
            v-model="$v.profile.city.$model" :class="{ 'is-invalid': $v.profile.city.$error, }" />
          <div v-if="$v.profile.city.$error" class="invalid-feedback">
            <span v-if="!$v.profile.city.required">{{ $t('Please enter field', { msg: $t('City') }) }}</span>
          </div>
        </div>

        <div class="mt-4 position-relative">
          <h6 class="mb-2">{{ $t('Zip Code') }}</h6>
          <input class="form-control mb-0 h50" type="text" :placeholder="$t('Zip Code')" aria-label="Zip Code"
            v-model="$v.profile.zip_code.$model" :class="{ 'is-invalid': $v.profile.zip_code.$error, }" />
          <div v-if="$v.profile.zip_code.$error" class="invalid-feedback">
            <span v-if="!$v.profile.zip_code.required">{{ $t('Please enter field', { msg: $t('Zip Code') }) }}</span>
          </div>
        </div>
      </div>

      <div class="mt-4 position-relative">
        <h6 class="mb-2">{{ $t('Email Address') }}</h6>
        <input class="form-control mb-0 h50" type="text" :placeholder="$t('Email')" aria-label="Email"
          v-model="$v.profile.user_email.$model" :class="{
            'is-invalid': $v.profile.user_email.$error,
          }" />
        <div v-if="$v.profile.user_email.$error" class="invalid-feedback">
          <span v-if="!$v.profile.user_email.required">{{ $t('Please enter email') }}</span>
          <span v-if="!$v.profile.user_email.email">{{ $t('This value should be a valid email') }}</span>
        </div>
      </div>






      <input type="hidden" v-model="profile.country_code">
      <div class="mt-4 position-relative">
        <h6 class="mb-2">{{ $t('Phone Number') }}</h6>
        <input class="form-control mb-0 h50" type="text" placeholder="Phone" aria-label="Phone"
          v-model="profile.mobile_number" readonly />
      </div>
    </div>
    <div class="mt-4 position-relative" v-if="languageArray.length > 1">
      <h6 class="mb-2">{{ $t('Select Language') }}</h6>
      <select v-model="profile.is_langauge" class="custom-select" :class="'ccc' + profile.is_langauge">
        <option v-for="lang in languageArray" :key="lang.value"
          :class="lang.value == $store.state.lang.locale ? 'selected' : ''" :value="lang.value"
          :selected="lang.value == $store.state.lang.locale ? 'selected' : ''">{{ lang.text }}
        </option>
      </select>
      <!-- <select class="custom-select" @change="languageChange" v-model="language" >
            <option :value="l.value" v-for="l in languages" :key="l.value">{{l.text}}</option>
          </select> -->
    </div>
    <div class="deliveryaddress mt-5 profile-bottom border-top pt-3">
      <div class="full-btn px-3" id="editprofile">
        <button @click="editProfile">{{ $t('Update') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required, email } from "vuelidate/lib/validators";
import { getLanguageText } from '../../helper/helper';

export default {
  data() {
    return {
      profile: {
        user_email: "",
        user_name: "",
        last_name: "",
        street_name: "",
        city: "",
        zip_code: "",
        mobile_number: "",
        country_code: "",
        is_langauge: "en"
      },
      prim_lang: '',
      seco_lang: '',
      language: '',
      languageArray: [],

    };
  },
  computed: {
    lang: {
      get: function () {
        return this.$store.state.lang.locale
      },
      set: function (newVal) {
        this.$store.dispatch('lang/changeLangLocale', newVal)
      }
    }
  },
  validations: {
    profile: {
      user_email: {
        required,
        email,
      },
      user_name: {
        required,
      },
      last_name: {
        required,
      },
      street_name: {
        required,
      },
      city: {
        required,
      },
      zip_code: {
        required,
      },
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.$auth.user && this.$auth.setting) {
        this.profile = {
          user_email: this.$auth.user.user_email,
          user_name: this.$auth.user.user_name,
          last_name: this.$auth.user.last_name,
          street_name: this.$auth.user.street_name,
          city: this.$auth.user.city,
          zip_code: this.$auth.user.zip_code,
          mobile_number: this.$auth.user.mobile_number,
          country_code: this.$auth.user.country_code,
          is_langauge: this.$store.state.lang.locale
        };
        this.languageArray = [
          { 'value': this.$auth.setting.primary_lang, text: getLanguageText(this.$auth.setting.primary_lang) },
        ];
        if (this.$auth.setting.secondary_lang) {
          this.languageArray.push({ 'value': this.$auth.setting.secondary_lang, text: getLanguageText(this.$auth.setting.secondary_lang) });
        }
        this.prim_lang = this.$auth.setting.primary_lang,
          this.seco_lang = this.$auth.setting.secondary_lang
      } else {
        this.$router.push({ name: "sign-in" });
      }
    }, 1000);
  },
  methods: {
    ...mapActions("user", ["updateUserDetails"]),

    editProfile() {
      this.$v.$touch();
      if (this.$v.profile.$invalid) {
        return;
      } else {
        this.updateUserDetails(this.profile).then((data) => {
          if (data.code == 200) {
            this.lang = this.profile.is_langauge;
            localStorage.setItem('localelang', this.profile.is_langauge);
            this.$auth.setUserDetails(data.user_details);
            this.$toasted.success(this.$t('Profile update successfully'), { duration: 2000 });
          } else {
            this.$toasted.success(data.msg, { duration: 2000 });
          }
        });
      }
    },
    languageChange() {
      localStorage.setItem('language', this.language);
    },

  },
};
</script>